import React from "react";

const NotFoundPage = () => (
  <div>
    <h1>Page introuvale (404)</h1>
    <p>Désolé, mais la page demandée n'existe pas...</p>
  </div>
);

export default NotFoundPage;
